import React from 'react';
import Loader from 'react-loader-spinner';
const dotImage  = require("../assets/img/dots.png");

function NolischeLoader(props)
{
    return (
        <div style={{
            height: '100vh', width: '100%',
            backgroundPosition: 'center center',
            backgroundImage: `url(${dotImage})`,
            backgroundRepeat: 'repeat',
            }}>
            <Loader
                style={{
                    margin: '0',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }}
                type="Bars"
                color="rgba(66,156,59,0.5)"
                height={50}
                width={50}
            />
        </div>
    );
}

export default NolischeLoader;