import "babel-polyfill";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import {ConnectedRouter} from 'connected-react-router';
import { OidcProvider } from "redux-oidc";
import { PersistGate } from 'redux-persist/integration/react';

import mainStarterInstance from "./utils/applicationStarter";

import NolischeLoader from './utils/nolischeLoader';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export function startFunction(userManager, history, storeData, MainContainer)
{
    ReactDOM.render(
        <Provider store={storeData.store}>
            <PersistGate loading={<NolischeLoader />} persistor={storeData.persistor}>
                <OidcProvider store={storeData.store} userManager={userManager}>
                    <ConnectedRouter history={history}>
                        <Suspense fallback={<NolischeLoader />}>
                                <MainContainer />
                        </Suspense>
                    </ConnectedRouter>
                </OidcProvider>
            </PersistGate>
        </Provider>
    , document.getElementById('root'));

    if (process.env.NODE_ENV === 'production') 
    {
        if ('serviceWorker' in navigator) {
            const runtime = require('serviceworker-webpack-plugin/lib/runtime');
            const registration = runtime.register();
        }
    }
}

mainStarterInstance.pullConfigAndStartAppAsync(startFunction);


