
/*
Write the Actions that are called to Mutate State on the store
 */
import {
    REQUEST_USER_DATA,
    SET_USER_DATA,
    SET_USER_DATA_ERROR,
    CLEAR_USER_DATA,
    LOGOUT_USER,
    LOGIN_USER,
} from './constants'


export const setUserData = (data) => ({
    type: SET_USER_DATA,
    data: data,
});


export const setUserDataError = (data) => ({
    type: SET_USER_DATA_ERROR,
    data: data,
});


export const requestUserData = (data) => ({
    type: REQUEST_USER_DATA,
    data: data,
});

export const clearUserData = () => ({
    type: CLEAR_USER_DATA,
});

export const logout = (id_token) => ({
    type: LOGOUT_USER,
    data: {id_token:id_token}
});


export const login = (loginError, currentPath) => ({
    type: LOGIN_USER,
    loginError,
    userLocation: (currentPath != null) ? currentPath.includes('/oauth2-logout')?'/main':'/main':currentPath
});





