import {
    SET_PRODUCT_DATA,
    CLEAR_PRODUCT_DATA,
    SET_PRODUCT_DATA_ERROR,
    SET_CURRENT_PRODUCT_VERSIONS_DATA,
    SET_CURRENT_PRODUCT_VERSIONS_DATA_ERROR,
} from './constants';

import produce from 'immer';

export const initialState = {
    loaded: false,
    raised_error: false,
    productData: null,
    error: null,
    currentProductVersions: {
        loaded: false,
        raised_error: false,
        productVersionsData: null,
        error: null,
    },
}

export default function productDataReducer(currentState = initialState, action)
{
    return produce(currentState, draft => {
        switch (action.type) {
            case SET_PRODUCT_DATA:
                {
                    draft.loaded = true;
                    draft.raised_error = initialState.raised_error;
                    draft.error = initialState.error;
                    draft.productData = action.data;
                }
                break;
            case SET_PRODUCT_DATA_ERROR:
                {
                    draft.raised_error = true;
                    draft.error = action.data;
                    draft.loaded = initialState.loaded;
                    draft.productData = initialState.productData;
                }
                break;
        
            case SET_CURRENT_PRODUCT_VERSIONS_DATA:
                {
                    draft.currentProductVersions.loaded = true;
                    draft.currentProductVersions.raised_error = initialState.currentProductVersions.raised_error;
                    draft.currentProductVersions.error = initialState.currentProductVersions.error;
                    draft.currentProductVersions.productVersionsData = action.data;
                }
                break;
            
            case SET_CURRENT_PRODUCT_VERSIONS_DATA_ERROR:
                {
                    draft.currentProductVersions.raised_error = true;
                    draft.currentProductVersions.error = action.data;
                    draft.currentProductVersions.loaded = initialState.currentProductVersions.loaded;
                    draft.productVersionsData.productData = initialState.currentProductVersions.productData;
                }
                break;  
            case CLEAR_PRODUCT_DATA:
                {
                    draft.loaded = false;
                    draft.productData = null;
                    if(draft.currentProductVersions !== null || draft.currentProductVersions.productVersionsData !== null)
                    {
                        draft.currentProductVersions.loaded = false;
                        draft.currentProductVersions.productVersionsData = null;
                    }
                }
                break;  
          default:
            return;
        }
      }
    );
}