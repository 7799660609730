import {
    REQUEST_USER_DATA,
    LOGOUT_USER,
    LOGIN_USER,
} from './constants';

import {getResource} from '../../utils/restResource';

import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from "connected-react-router";

import { 
    setUserData,
    setUserDataError
} from './actions';

import mainStarterInstance from '../../utils/applicationStarter';

export function* requestUserData(action) 
{
    const response = yield call(
        getResource, 
        action.data.baseURL,
        action.data.headers, 
        '/users');

    if (response.error) {
      yield put(setUserDataError(response.error));
    } else {
      yield put(setUserData(response.data));
      yield put(push(action.data.currentLocation));
    }
}


function* logoutUser(action) 
{
  mainStarterInstance.userManager.signoutRedirect({ 'id_token_hint': action.data.id_token });
  mainStarterInstance.userManager.removeUser();
}

function* login(action) 
{
  mainStarterInstance.userManager.removeUser();
  mainStarterInstance.userManager.signinRedirect({data: { path: action.userLocation}}).catch(error => {
    action.loginError(error);
  });
}


export default function* userDataSaga() {
  yield all([
    takeLatest(REQUEST_USER_DATA, requestUserData),
    takeLatest(LOGOUT_USER, logoutUser),
    takeLatest(LOGIN_USER, login)]);
}