import React from 'react'

import { createUserManager } from "redux-oidc";

import { createBrowserHistory } from "history";

import configureStore from "./configureStore";

import axios from "axios";


const App = React.lazy(() => import('../App'));

class MainStarter
{
  userManager = null;
  config = null;

  pullConfigAndStartAppAsync = async function (startFunction)
  {
    const response = await axios.get(`/appsettings.json?${__webpack_hash__}`);
    const configData = await response.data;
    const baseURL = configData.accountServiceBaseURL;

    const userManagerConfig = {
      authority: `${baseURL}/.well-known/openid-configuration`,
      client_id: "nolische_website",
      redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : "" }/oauth2-redirect`,
      post_logout_redirect_uri: `${window.location.protocol}//${ window.location.hostname }${window.location.port ? `:${window.location.port}` : ""}/oauth2-logout`,
      silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silentRenew.html`,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: false,
      scope: "openid profile account.access user.access",
      response_type: "code",
      monitorSession: true,
      revokeAccessTokenOnSignout: true,
    };
      
    this.config = configData;
    this.userManager = createUserManager(userManagerConfig);

    
    const history = createBrowserHistory({ basename: '/#/' });
    
    const storeData = configureStore(history, this.userManager);

    startFunction(this.userManager, history, storeData, App);
  }
}

const mainStarterInstance = new MainStarter();

export default mainStarterInstance;
