import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'



function useLocalStorage(key)
{
    return {
        key,
        storage,
        stateReconciler: autoMergeLevel2,
    }
}

function useSessionStorage(key)
{
    return {
        key,
        storage: storageSession,
        stateReconciler: autoMergeLevel2,
    }
}

export default {useLocalStorage, useSessionStorage};