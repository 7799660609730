//Nolische Data
import userDataSaga from "./UserData/saga";
import productDataSaga from "./ProductData/saga";


export default function injectSagas(store)
{
    store.injectedSagas = {
        'userDataReducer':{
            mode: "@@saga-injector/daemon", 
            saga: userDataSaga,
            task: store.runSaga(userDataSaga, null)
        },
        'productDataReducer':{
            mode: "@@saga-injector/daemon", 
            saga: productDataSaga,
            task: store.runSaga(productDataSaga, null)
        },
    }
}
