//Nolische Data
import userDataReducer from "./UserData";
import productDataReducer from "./ProductData";
import {default as StoreConfig} from '../utils/storePersistConfigGenerator'
import { persistReducer } from 'redux-persist'


//Store
//Nolische Data
const storeRepo = {
    userDataReducer: persistReducer(StoreConfig.useSessionStorage('userDataReducer'), userDataReducer),
    productDataReducer: persistReducer(StoreConfig.useSessionStorage('productDataReducer'), productDataReducer),
}

export default storeRepo;
