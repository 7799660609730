
/*
Write the Actions that are called to Mutate State on the store
 */
import {
    REQUEST_PRODUCT_DATA,
    SET_PRODUCT_DATA,
    CLEAR_PRODUCT_DATA,
    SET_PRODUCT_DATA_ERROR,
    SET_CURRENT_PRODUCT_VERSIONS_DATA,
    SET_CURRENT_PRODUCT_VERSIONS_DATA_ERROR,
    REQUEST_PRODUCT_VERSIONS_DATA,
} from './constants'


export const setProductData = (data) => ({
    type: SET_PRODUCT_DATA,
    data: data,
});
export const setProductVersionsData = (data) => ({
    type: SET_CURRENT_PRODUCT_VERSIONS_DATA,
    data: data,
});


export const setProductDataError = (data) => ({
    type: SET_PRODUCT_DATA_ERROR,
    data: data,
});
export const setProductVersionsDataError = (data) => ({
    type: SET_CURRENT_PRODUCT_VERSIONS_DATA_ERROR,
    data: data,
});


export const requestProductData = (data) => ({
    type: REQUEST_PRODUCT_DATA,
    data: data,
});
export const requestProductVersionsData = (data) => ({
    type: REQUEST_PRODUCT_VERSIONS_DATA,
    data: data,
});

export const clearProductData = () => ({
    type: CLEAR_PRODUCT_DATA,
});




