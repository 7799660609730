import {
    SET_USER_DATA,
    SET_USER_DATA_ERROR,
    CLEAR_USER_DATA,
} from './constants';

import { put } from 'redux-saga/effects';
import produce from 'immer';

export const initialState = {
    loaded: false,
    raised_error: false,
    userData: null,
    error: null,
}

export default function userDataReducer(currentState = initialState, action)
{
    return produce(currentState, draft => {
        switch (action.type) {
            case CLEAR_USER_DATA:
                {
                    draft.loaded = initialState.loaded;
                    draft.userData = initialState.userData;
                    draft.error = initialState.error;
                    draft.raised_error = initialState.raised_error;
                }
                break;
            case SET_USER_DATA:
                {
                    draft.loaded = true;
                    draft.raised_error = initialState.raised_error;
                    draft.error = initialState.error;
                    draft.userData = action.data;
                }
                break;
            case SET_USER_DATA_ERROR:
                {
                    draft.raised_error = true;
                    draft.error = action.data;
                    draft.loaded = initialState.loaded;
                    draft.userData = initialState.userData;
                }
                break;
    
          default:
            return;
        }
      }
    );
}