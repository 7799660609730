import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import createSagaMiddleware from 'redux-saga/';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as oidcDataReducer } from 'redux-oidc';

import { persistStore, persistReducer } from 'redux-persist'
import {default as StoreConfig} from './storePersistConfigGenerator'


import storeRepo from "../StoreRepo";
import injectStoreRepoSagas from "../StoreRepo/injectStoreRepoSagas";


export default function configureStore(history, userManager) 
{
    const initialState = {};
    const composeEnhancer = GetComposeEnhancer();

    const reduxSagaMonitorOptions = {};
    const sagaMiddlewareInstance = createSagaMiddleware(reduxSagaMonitorOptions);
    const routerMiddlewareInstance = routerMiddleware(history);

    const middlewares = [sagaMiddlewareInstance, routerMiddlewareInstance];
    const enhancers = [applyMiddleware(...middlewares)];

    const storeReducers = {
        oidc: persistReducer(StoreConfig.useLocalStorage('oidc'),oidcDataReducer),
        router: persistReducer(StoreConfig.useSessionStorage('router'),connectRouter(history)),
        ...storeRepo
    }
    const store = createStore(
        combineReducers(storeReducers),
        initialState,
        composeEnhancer(...enhancers),
    );
    // Extensions
    store.runSaga = sagaMiddlewareInstance.run;
    store.injectedReducers = {}; // Reducer registry

    //Set the sagas per reducer
    injectStoreRepoSagas(store);

    let persistor = persistStore(store);
    return {store, persistor};
}

function GetComposeEnhancer()
{
    ////////////DEV-SUPPORT///////////////
     // In development, use the browser's Redux dev tools extension if installed
     const isDevelopment = process.env.NODE_ENV === "development";
     if (
         isDevelopment &&
         typeof window !== "undefined" &&
         window.devToolsExtension
     ){
         return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
     }

     return compose;
    ////////////DEV-SUPPORT///////////////
}