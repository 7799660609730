import {
    REQUEST_PRODUCT_DATA,
    REQUEST_PRODUCT_VERSIONS_DATA,
} from './constants';

import {getResource} from '../../utils/restResource';

import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from "connected-react-router";

import { 
    setProductData,
    setProductDataError,
    setProductVersionsData,
    setProductVersionsDataError,
} from './actions';

export function* requestProductData(action) 
{
    const response = yield call(
        getResource, 
        action.data.baseURL,
        action.data.headers, 
        '/products');

    if (response.error) {
      yield put(setProductDataError(response.error));
    } else {
      yield put(setProductData(response.data));
    }
}

export function* requestProductVersionsData(action) 
{
    const response = yield call(
        getResource, 
        action.data.baseURL,
        action.data.headers, 
        `products/${action.data.productId}/product-versions`);

    if (response.error) {
      yield put(setProductVersionsDataError(response.error));
    } else {
      yield put(setProductVersionsData(response.data));
    }
}

export default function* productDataSaga() {
  yield all([
    takeLatest(REQUEST_PRODUCT_DATA, requestProductData),
    takeLatest(REQUEST_PRODUCT_VERSIONS_DATA, requestProductVersionsData),
  ]);
}