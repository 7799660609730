require("es6-promise").polyfill();
import axios from 'axios';

/**
 * Gets a resource given a URL, returning a promise
 * @param  {string} [baseURL]      The base URL that the request is sent to
 * @param  {string} [headers]  The the headers for the request
 * @param  {string} [path]      The path to a resource we are requesting
 * @param  {string} [queryString] The query string to attach to the URL e.g. 'lat=-34.045688,&long=18.3590362,17z'
 * @param  {object} [queryStringParams] - must be a plain object or a URLSearchParams object  e.g. { ID: 12345 }
 *
 * @return {object}           An object containing either "data" or "error"
 */

export function* getResource(baseURL, headers, path, queryString, queryStringParams) {
  const result = {};
  let requestConfig = {};

  if (!queryStringParams && queryString) {
    queryStringParams = new URLSearchParams(queryString);
  }

  if(headers)
  {
    requestConfig = {
        baseURL: baseURL,
        params: queryStringParams,
        headers: headers
      };
  }else
  {
    requestConfig = {
        baseURL: baseURL,
        params: queryStringParams,
      };
  }

  yield axios
    .get(path, requestConfig)
    .then(function(response) {
      result.data = response.data;
    })
    .catch(function(error) {
      if(error.response != null){
        result.error = (error.response.data)?error.response.data:{};
        result.error.status = error.response.status;
      }else{
        result.error = {name: error.name, message: error.message};
        result.error.status = -1;
      }
    })
    .finally(function() {
      // always executed
    });

  return result;
}