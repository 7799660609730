
//REDUX
export const SET_PRODUCT_DATA = 'product-data/SET_PRODUCT_DATA';
export const SET_PRODUCT_DATA_ERROR = 'product-data/SET_PRODUCT_DATA_ERROR';
export const CLEAR_PRODUCT_DATA = 'product-data/CLEAR_PRODUCT_DATA';

export const SET_CURRENT_PRODUCT_VERSIONS_DATA = 'product-data/versions/SET_CURRENT_PRODUCT_VERSION_DATA';
export const SET_CURRENT_PRODUCT_VERSIONS_DATA_ERROR = 'product-data/versions/SET_CURRENT_PRODUCT_VERSION_DATA';


//REDUX-SAGAS - Actions that trigger redux sagas
export const GET_PRODUCT_DATA = 'product-data/GET_PRODUCT_DATA';
export const GET_PRODUCT_VERSIONS_DATA = 'product-data/versions/GET_PRODUCT_VERSION_DATA';


//SAGAS
export const REQUEST_PRODUCT_DATA = 'product-data/REQUEST_PRODUCT_DATA';
export const REQUEST_PRODUCT_VERSIONS_DATA = 'product-data/versions/REQUEST_PRODUCT_VERSION_DATA';


