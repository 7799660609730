
//REDUX
export const SET_USER_DATA = 'user-data/SET_USER_DATA';
export const SET_USER_DATA_ERROR = 'user-data/SET_USER_DATA_ERROR';
export const CLEAR_USER_DATA = 'user-data/CLEAR_USER_DATA';


//REDUX-SAGAS - Actions that trigger redux sagas
export const GET_USER_DATA = 'user-data/GET_USER_DATA';


//SAGAS
export const REQUEST_USER_DATA = 'user-data/REQUEST_USER_DATA';
export const LOGOUT_USER = 'user-data/LOGOUT_USER';
export const LOGIN_USER = 'idServer/LOGIN_USER';

